<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row>
      <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
        <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

        </v-breadcrumbs>
      </div>
    </v-row><br><br><br />
    <v-card class="card">
      <v-card-title class="heading justify-center">
        <span>Add User</span>
      </v-card-title>
      <div v-if="init_loading">
        <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
      </div>
      <div v-else>
        <v-card-text>
          <div class="add-section text">
            <div>
              <v-form ref="form">
                <v-col>
                  <v-row class="justify-center">
                    <v-col>
                      <label class="add-text">Organization</label><span style="color: red; font-weight: bolder">*</span>
                      <v-autocomplete v-model="selecteduserorg" :items="orglist" item-text="org_name" item-value="org_id"
                        outlined dense :rules="[rules.required]" clearable required></v-autocomplete>
                    </v-col>
                    <v-col class="pb-0 ">
                      <label class="add-text">User Name</label><span style="color: red; font-weight: bolder">*</span>
                      <v-text-field v-model="username" dense outlined :rules="[rules.required]" clearable
                        required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="justify-center">
                    <v-col class="pb-0">
                      <label class="add-text">Registration Number / Employee Code</label><span
                        style="color: red; font-weight: bolder">*</span>
                      <v-text-field v-model="memberId" dense outlined :rules="[rules.required]" clearable
                        required></v-text-field>
                    </v-col>

                  </v-row>
                  <v-row class="justify-center">
                    <v-col class="p-btn">
                      <v-btn small color="warning" v-on:click="checkUsername()">
                        Check Availability
                      </v-btn>

                    </v-col>
                  </v-row>
                  <v-row class="justify-center">
                    <v-col class="pb-0">
                      <label class="add-text">User Type</label><span style="color: red; font-weight: bolder">*</span>
                      <v-combobox v-model="selectedusertype" :items="userType" item-text="userType" outlined return-object
                        dense :rules="[rules.required]" clearable required></v-combobox>
                    </v-col>

                  </v-row>
                  <v-row class="justify-center">
                    <v-col class="text-center">
                      <v-btn depressed color="success" @click="saveUsername()">
                        ADD
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-form>
            </div>
          </div>
          <template>
            <v-data-table :headers="headers" :search="search" :items="userlist" :items-per-page="5" class="elevation-1">

              <template v-slot:top>
                <v-toolbar flat color="white" style="width: 50%; float: right;">
                  <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table" single-line
                    hide-details></v-text-field>
                </v-toolbar>
                <v-toolbar flat>

                  <v-dialog v-model="dialog" max-width="700px">
                    <v-card>
                      <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="4" md="4">
                              <v-text-field v-model="editedItem.user" label="User" disabled></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                              <v-autocomplete v-model="editedItem.usertype" :items="userType" item-text="userType"
                                label="User Type" return-object filled rounded dense></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                              <v-autocomplete v-model="editedItem.org" :items="orglist" item-text="org_name"
                                item-value="org_id" outlined dense :rules="[rules.required]" clearable
                                required></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                              <v-text-field v-model="editedItem.regno"
                                label="Registration Number / Employee code"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">
                          Cancel
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="save">
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                  mdi mdi-pencil-box
                </v-icon>
              </template>
            </v-data-table>

          </template>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard

export default {
  data: () => ({
    snackbar_msg: "",
    snackbar: false,
    search: "",
    color: "",
    username: "",
    userType: [],
    init_loading: false,
    userlist: [],
    orglist: [],
    memberId: "",
    usernamemessage: '',
    rules: {
      required: value => !!value || "Required."
    },
    items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/home',
      },
      {
        text: localStorage.getItem("jhhjbyj"),
        disabled: false,
        href: '/sublink',
      },
      {
        text: 'Add User',
        disabled: true,
        href: 'breadcrumbs_link_2',
      },
    ],
    selectedusertype: null,
    selecteduserorg: null,
    dialog: false,
    headers: [
      { text: 'User', value: 'user' },
      { text: 'User Type', value: 'usertype.userType' },
      { text: 'Registration Number/ Employee Code', value: 'regno' },
      { text: 'Orgnaiztion', value: 'organization' },
      { text: 'Edit', value: 'actions' },
    ],
    editedIndex: -1,
    editedItem: {
      userid: '',
      user: '',
      usertype: null,
      regno: '',
      org: '',
    },
    defaultItem: {
      userid: '',
      user: '',
      usertype: null,
      regno: '',
    },
  }),
  computed: {
    formTitle() {
      return 'Edit Item'
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    checkUsername: function () {
      if (this.selecteduserorg == null) {
        this.showSnackbar("#b71c1c", "Select Orgnaiztion");
        return
      }
      const data = {
        username: this.username,
        memberId: this.memberId,
        org: this.selecteduserorg,
      };
      axios.post("/admin/checkUsername_new", data)
        .then(res => {
          //console.log("res------->"+res.data.userType)
          if (res.data.msg == "200") {
            this.usernamemessage = res.data.message;
            if (res.data.code == 1)
              this.showSnackbar("#b71c1c", res.data.message);
            else
              this.showSnackbar("#4CAF50", res.data.message);
            //  console.log("res------->"+res.data.message);
          }
        })
        .catch(error => {
          window.console.log(error)
        })
    },
    saveUsername: function () {

      if (this.$refs.form.validate()) {
        const data = {
          username: this.username,
          memberId: this.memberId,
          selectedusertype: this.selectedusertype,
          org: this.selecteduserorg
        };
        axios.post("/admin/saveUsername_new", data)
          .then(res => {
            if (res.data.msg == "200") {
              if (res.data.code == 1)
                this.showSnackbar("#b71c1c", res.data.message);
              else
                this.showSnackbar("#4CAF50", 'Successfully created user!!');
              this.onLoad();
            }
          })
          .catch(error => {
            this.showSnackbar("#b71c1c", "Something went wrong!!!");
            window.console.log(error)
          })
      }
    },
    onLoad: function () {
      this.init_loading = true;
      axios.get("admin/fetchEmployeeType_new")
        .then(res => {
          //console.log("res------->"+res.data.userType)
          if (res.data.msg == "200") {
            this.init_loading = false;
            this.userType = res.data.userType,
              this.orglist = res.data.orglist,
              this.userlist = res.data.userlist
          }
        })
        .catch(error => {
          this.init_loading = false;
          window.console.log(error)
        })
    },
    editItem: function (item) {
      this.editedIndex = this.userlist.indexOf(item);
      console.log("in edit item----------", this.editedIndex)
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      console.log("in edit item----------", this.editedItem)
      console.log(this.dialog)
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save() {
      axios.post("/admin/updateUtype_new", this.editedItem)
        .then(res => {
          //console.log("res------->"+res.data.userType)
          if (res.data.msg == "200") {
            if (res.data.code == 1)
              this.showSnackbar("#4CAF50", "Successfully updated user!!");
            else
              this.showSnackbar("#b71c1c", res.data.message);
            this.onLoad();
          }
        })
        .catch(error => {
          this.showSnackbar("#b71c1c", "Something went wrong!!!");
          window.console.log(error)
        })
      this.close()
    },
  }
}
</script>
<style scoped>
.heading {
  background-color: #3f51b5;
  padding: 0.3rem;
  color: white;
}

.v-icon {
  cursor: pointer;
}

.text>>>.v-input__slot {
  background: white !important;
  border-color: hsla(190, 99%, 30%, 1) !important;
}

.p-btn {
  padding: 0px 12px 12px 12px;
}
</style>




